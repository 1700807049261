import { useSearchParams } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import heic2any from "heic2any";

import { env } from "../../env";
import { requestsRepository } from "../../api/RequestsRepository";
import { ChatUser } from "../../api/models/ChatUser";
import { ChatMessage } from "../../api/models/ChatMessage";
import { EditIcon } from "../../components/icons/EditIcon";
import { ReplyIcon } from "../../components/icons/ReplyIcon";
import { SendIcon } from "../../components/icons/SendIcon";
import { Messege } from "../../components/Messege/Messege";
import { formatDate, timeDiff } from "../../utils/formatDate";
import { CloseIcon } from "../../components/icons/CloseIcon";
import { AttachmentIcon } from "../../components/icons/AttachmentIcon";
import { FileIcon } from "../../components/icons/FileIcon";
import { DeleteIcon } from "../../components/icons/DeleteIcon";
import { ChatNameIcon } from "../../components/icons/ChatNameIcon";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { SettingsIcon } from "../../components/icons/SettingsIcon";
import { CameraIcon } from "../../components/icons/CameraIcon";
import { SearchIcon } from "../../components/icons/SearchIcon";
import { InfoIcon } from "../../components/icons/InfoIcon";
import { routes } from "../../routes";
import { RecordHistory } from "../../api/models/RecordHistory";
import { UserInformation } from "../../components/UserInformation/UserInformation";
import { setNewMessagesCount } from "../../redux/actions/Chat";
import { ReduxState } from "../../redux/store";
import { Chat } from "../../api/models/Chat";

import "./Messenger.scss";

interface IProps {
    fullMode: boolean;
    userId?: string | null;
    externalRoomId?: string | null;
    link?: string | null;
    visible: boolean;
    showChatsList?: boolean;
    authSessionId: any;
    serviceType: any;
}

export const Messenger = (props: IProps) => {
    const { fullMode, userId, visible, externalRoomId, link, showChatsList, authSessionId, serviceType } = props;

    const [params] = useSearchParams();
    
    const dispatch = useDispatch();
    
    const { newMessagesCount } = useSelector(({chat}: ReduxState) => chat);
    
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [room, setRoom] = useState<string | null>();
    const [sessionId, setSessionId] = useState<string | null>();
    const [message, setMessage] = useState<string>("");
    const [searchInput, setSearchInput] = useState<string>("");
    const [roomUserId, setRoomUserId] = useState<string | null>(userId ?? null);
    const [chatId, setChatId] = useState<string | null>(null);
    const [chatName, setChatName] = useState<string | null>(null);
    const [replyMessageId, setReplyMessageId] = useState<string | null>(null);
    const [editMessageId, setEditMessageId] = useState<string | null>(null);
    const [users, setUsers] = useState<ChatUser[]>([]);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [searchMessegies, setSearchMessegies] = useState<ChatMessage[]>([]);
    const [unreadMessages, setUnreadMessages] = useState<ChatMessage[]>([]);
    const [chats, setChats] = useState<any>([]);
    const [searchChats, setSearchChats] = useState<any>([]);
    const [searchChatInput, setSearchChatInput] = useState<string>("");
    const [newMessages, setNewMessages] = useState<any>([]);
    const [deleteMessages, setDeleteMessages] = useState<any>([]);
    const [editMessages, setEditMessages] = useState<any>([]);
    const [showSettings, setShowSettings] = useState<any>(null);
    const [showRecords, setShowRecords] = useState<boolean>(false);
    const [showUsers, setShowUsers] = useState<boolean>(false);
    const [showSearchMessegies, setShowSearchMessegies] = useState<boolean>(false);
    const [records, setRecords] = useState<RecordHistory[]>([]);
    const [showChatSettings, setShowChatSettings] = useState<boolean>(false);
    const [scrollToEnd, setScrollToEnd] = useState(false);
    const [scrollToMessage, setScrollToMessage] = useState<string | null>(null);
    const [sendFile, setSendFile] = useState(false);
    const [attachments, setAttachments] = useState<any>([]);
    const [newAttachments, setNewAttachments] = useState<any>([]);
    const [deleteAttachments, setDeleteAttachments] = useState<any>([]);
    const [readMessages, setReadMessages] = useState<any>([]);
    const [newUser, setNewUser] = useState<ChatUser[]>([]);
    const [newChat, setNewChat] = useState<Chat[]>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showList, setShowList] = useState(showChatsList);
    const messagesEndRef = useRef<any>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const textareaRef = useRef<any>(null);
    
    useDetectOutsideClick(ref, () => setShowChatSettings(false));

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(env().REACT_APP_CHAT_HUB_URL ?? "")
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    useEffect(() => {
        if (userId) {
            setRoomUserId(userId);
        }
    }, [userId]);

    useLayoutEffect(() => {
        return () => {
            if (connection) {
                connection?.stop().then(() => {
                    setConnection(null);
                });
            }
        }
    }, [connection]);

    useEffect(() => {
        if (connection !== null) {
            connection.start().then(() => {
                if (fullMode) {
                    requestsRepository.ChatApiService.getChats(roomUserId, authSessionId).then((res) => {
                        if (res.success) {
                            setChats(res.result);
                            setSearchChats(res.result);
                            if (res.result.length > 0) {
                                const room = res.result.find(x => x.roomId === externalRoomId);
                                const chatRoomId = room?.roomId ?? res.result[0].roomId;
                                setRoom(chatRoomId);
                                const request: any = {
                                    userId: roomUserId,
                                    roomId: chatRoomId,
                                    sessionId: authSessionId,
                                    serviceType
                                }
                                sendMessageSignal("JoinChat", request);
                            }
                        }
                    });
                } else {
                    setRoom(params.get('roomId'));
                    const request: any = {
                        userId: roomUserId,
                        roomId: params.get('roomId'),
                        sessionId: authSessionId,
                        serviceType
                    }
                    sendMessageSignal("JoinChat", request);
                }
            });
        }
    }, [connection]);

    useEffect(() => {
        if (connection !== null) {
            connection?.on("JoinChatResponse", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setChatId(message.chatId);
                    setRoomUserId(message.id);
                    setSessionId(message.sessionId);
                    setChatName(message.chatName);
                    setIsAdmin(message.isAdmin);
                    requestsRepository.ChatApiService.getUsers(message.chatId).then(res => {
                        if (res.success) {
                            setUsers(res.result);
                        }
                    });
                    requestsRepository.ChatApiService.getChatUnreadMessages(message.chatId, message.id).then((res) => {
                        if (res.success) {
                            setUnreadMessages(res.result);
                            dispatch(setNewMessagesCount(res.result.length));
                        }
                        requestsRepository.ChatApiService.getMessages(message.chatId, {headers: {"service-type":serviceType}}).then(res => {
                            if (res.success) {
                                setMessages(res.result);
                                setScrollToEnd(false);
                            }
                        });
                    });
                }
            });
            connection?.on("NewUser", (message: any) => {
                setNewUser([...newUser, message]);
            });
            connection?.on("NewMessage", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setNewMessages([...newMessages, message]);
                }
            });
            connection?.on("EditMessage", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setEditMessages([...editMessages, message]);
                }
            });
            connection?.on("DeleteMessage", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setDeleteMessages([...deleteMessages, message]);
                }
            });
            connection?.on("ReadMessage", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setReadMessages([...readMessages, message]);
                }
            });
            connection?.on("NewChat", (message: any) => {
                setNewChat([...newChat, message.chat]);
            });
        }
    }, [connection]);

    useEffect(() => {
        if (newUser.length > 0) {
            newUser.forEach(item => {
                if (!users.find(x => x.id === item.id)) {
                    setUsers([...users, item]);
                }
            });
            setNewUser([]);
        }
    }, [newUser]);
    
    useEffect(() => {
        if (newChat.length > 0 && fullMode) {
            newChat.forEach(item => {
                const chatsData = [item, ...chats];
                setChats(chatsData);
                setSearchChats(searchChatInput?.trim()?.length > 0 ? chatsData.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chatsData);
            });
            setNewChat([]);
        }
    }, [newChat]);
    

    useEffect(() => {
        if (newMessages.length > 0) {
            newMessages.forEach((x: any) => {
                if (x.chatId === chatId) {
                    if (x.userId === roomUserId) {
                        const data = [...messages];
                        const idx = data.findIndex(d => d.id === "-1");
                        if (idx !== -1) {
                            data[idx].id = x.id;
                            data[idx].createdDate = x.createdDate;
                            setMessages(data);
                        }
                        if (fullMode) {
                            let chatsData = [...chats];
                            const chatIdx = chatsData.findIndex(c => c.id === x.chatId);
                            chatsData[chatIdx].messages = [data[idx]];
                            const chatWithMessage = chatsData[chatIdx];
                            chatsData = chatsData.filter(c => c.id !== x.chatId);
                            chatsData.unshift(chatWithMessage);
                            setChats(chatsData);
                            setSearchChats(searchChatInput?.trim()?.length > 0 ? chatsData.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chatsData);
                        }
                    } else {
                        dispatch(setNewMessagesCount(newMessagesCount + 1));
                        const messageData = {
                            id: x.id,
                            userId: x.userId,
                            messageText: x.messageText,
                            createdDate: x.createdDate,
                            replyMessageId: x.replyMessageId,
                            attachments: x.attachments,
                            isRead: x.isRead
                        };
                        setMessages([...messages, messageData]);
                        if (fullMode) {
                            let chatsData = [...chats];
                            const chatIdx = chatsData.findIndex(c => c.id === x.chatId);
                            chatsData[chatIdx].unreadUserMessagesCount = chatsData[chatIdx].unreadUserMessagesCount + 1;
                            chatsData[chatIdx].messages = [messageData];
                            const chatWithMessage = chatsData[chatIdx];
                            chatsData = chatsData.filter(c => c.id !== x.chatId);
                            chatsData.unshift(chatWithMessage);
                            setChats(chatsData);
                            setSearchChats(searchChatInput?.trim()?.length > 0 ? chatsData.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chatsData);
                        }
                        setUnreadMessages([...unreadMessages, messageData]);
                    }
                } else if (fullMode) {
                    let chatsData = [...chats];
                    const chatIdx = chatsData.findIndex(c => c.id === x.chatId);
                    if (chatIdx !== -1) {
                        const messageData = {
                            id: x.id,
                            userId: x.userId,
                            messageText: x.messageText,
                            createdDate: x.createdDate,
                            replyMessageId: x.replyMessageId,
                            attachments: x.attachments,
                            isRead: x.isRead
                        };
                        chatsData[chatIdx].unreadUserMessagesCount = chatsData[chatIdx].unreadUserMessagesCount + 1;
                        chatsData[chatIdx].messages = [messageData];
                        const chatWithMessage = chatsData[chatIdx];
                        chatsData = chatsData.filter(c => c.id !== x.chatId);
                        chatsData.unshift(chatWithMessage);
                        setChats(chatsData);
                        setSearchChats(searchChatInput?.trim()?.length > 0 ? chatsData.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chatsData);
                        setUnreadMessages([...unreadMessages, messageData]);
                    }
                }
            });
            setNewMessages([]);
            setScrollToEnd(false);
        }
    }, [newMessages]);

    useEffect(() => {
        if (editMessages.length > 0) {
            editMessages.forEach((x: any) => {
                if (x.chatId === chatId) {
                    const data = [...messages];
                    const idx = data.findIndex(d => d.id === x.id);
                    data[idx].messageText = x.messageText;
                    data[idx].attachments = [...data[idx].attachments?.filter(a => !x.deleteAttachments.includes(a.id)), ...x.attachments];
                    setMessages(data);
                }
            });
            setEditMessages([]);
        }
    }, [editMessages]);

    useEffect(() => {
        if (deleteMessages.length > 0) {
            deleteMessages.forEach((x: any) => {
                if (x.chatId === chatId) {
                    setMessages([...messages.filter(d => d.id !== x.id)]);
                }
            });
            setDeleteMessages([]);
        }
    }, [deleteMessages]);

    useEffect(() => {
        if (readMessages.length > 0) {
            readMessages.forEach((x: any) => {
                if (x.chatId === chatId) {
                    const data = [...messages];
                    const idx = data.findIndex(d => d.id === x.id);
                    if (idx !== -1) {
                        data[idx].isRead = true;
                        setMessages(data);
                    }
                }
            });
            setReadMessages([]);
        }
    }, [readMessages]);

    useEffect(() => {
        if (visible && !scrollToEnd && users.length > 0 && messages.length > 0) {
            const unreadElement: any = document.querySelector('.unread');
            let scroll =
                messagesEndRef.current.scrollHeight -
                messagesEndRef.current.clientHeight;
            if (unreadElement) {
                scroll = unreadElement.offsetTop;
            }

            messagesEndRef.current.scrollTo(0, scroll);
            setScrollToEnd(true);
        }
    }, [messages, users, scrollToEnd, visible]);

    useEffect(() => {
        if (scrollToMessage !== null) {
            const element: any = document.getElementById(scrollToMessage);
            if (element) {
                const scroll = element.offsetTop - 50;
                messagesEndRef.current.scrollTo(0, scroll);
            }

            setScrollToMessage(null);
        }
    }, [scrollToMessage]);

    useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${message.length > 0 ? Math.max(
            textareaRef.current.scrollHeight,
            26
        ) : 26}px`;
    }, [message]);

    const sendMessageSignal = (methodName: any, request: any) => {
        if (connection !== null) {
            if (request !== null) {
                connection.invoke(methodName, request);
            } else {
                connection.invoke(methodName);

            }
        }
    }

    const getUser = (userId: any) => {
        return users.find(x => x.userId === userId);
    }

    const getMessage = (id: any) => {
        return messages.find(x => x.id === id);
    }

    const sendMessage = () => {
        if (roomUserId && chatId && !isSendDisabled()) {
            if (editMessageId !== null) {
                const attachmentsData = attachments && attachments?.lenght > 0 ? attachments?.filter((x: any) => newAttachments.includes(x)) : [];
                const request = {userId: roomUserId, chatId, message, messageId: editMessageId, attachments: attachmentsData, deleteAttachments};
                sendMessageSignal("EditMessage", request);
                const data = [...messages];
                const idx = data.findIndex(d => d.id === editMessageId);
                data[idx].messageText = message;
                data[idx].attachments = attachments;
                setMessages(data);
                setMessage("");
                setAttachments([]);
                setNewAttachments([]);
                setDeleteAttachments([]);
                setEditMessageId(null);
            } else {
                const data = [...messages, {
                    id: "-1",
                    userId: roomUserId,
                    messageText: message,
                    createdDate: new Date(),
                    attachments,
                    replyMessageId: replyMessageId,
                    isRead: false
                }];
                const request = {userId: roomUserId, chatId, message, replyMessageId, attachments};
                setMessages(data);
                setAttachments([]);
                setMessage("");
                setReplyMessageId(null);
                sendMessageSignal("SendMessage", request);
                setScrollToEnd(false);
            }
        }
    }

    const deleteMessage = (messageId: any) => {
        if (roomUserId && chatId && messageId !== null) {
            const request = {userId: roomUserId, chatId, messageId};
            sendMessageSignal("DeleteMessage", request);
            setMessages([...messages.filter(x => x.id !== messageId)]);
        }
    }

    const readMessage = (id: any, updateMessage: any) => {
        if (roomUserId && chatId) {
            const request = {userId: roomUserId, chatId, messageId: id, updateMessage};
            sendMessageSignal("ReadMessage", request);
            setUnreadMessages([...unreadMessages.filter(x => x.id !== id)]);
            if (newMessagesCount > 0) {
                dispatch(setNewMessagesCount(newMessagesCount - 1));
            }
            if (fullMode) {
                const chatsData = [...chats];
                const chatIdx = chatsData.findIndex(c => c.id === chatId);
                chatsData[chatIdx].unreadUserMessagesCount = chatsData[chatIdx].unreadUserMessagesCount - 1;
                setChats(chatsData);
                setSearchChats(searchChatInput?.trim()?.length > 0 ? chatsData.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chatsData);
            }
        }
    }

    const groupBy = (xs: any, key: any) => {
        return xs.reduce((rv: any, x: any) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const getGroupedMessages = (key: any = null) => {
        var data = groupBy(messages.map(x => ({...x, groupDate: formatDate(x.createdDate, 'MM-DD-yyyy')})), 'groupDate');
        return key !== null ? data[key] : data;
    }

    const getDateText = (date: any) => {
        return (new Date()).toDateString() === (new Date(date)).toDateString() ? 'Сегодня' : formatDate(new Date(date), 'DD MMMM');
    }

    const onChangeFile = useCallback(
        async (data: any) => {
            if (data && data.currentTarget && data.currentTarget.files && data.currentTarget.files.length > 0) {
                setSendFile(true);
                const attachmentsData = [];
                for (const file of data.currentTarget.files) {
                    const name = file.name;
                    let type = file.type;
                    let reqFile = file;
                    if (name?.toLowerCase()?.includes('heic')|| name?.toLowerCase()?.includes('heif')) {
                        const heicRes = await heic2any({ blob: file });
                        reqFile = new File(Array.isArray(heicRes) ? heicRes : [heicRes], file.name?.replace('.heic', '.png')?.replace('.heif', '.png')?.replace('.HEIC', '.png')?.replace('.HEIF', '.png'), {type: "image/png"});
                        type = "image/png";
                    }
                    const result = await requestsRepository.AttachmentApiService.addAttachment(reqFile,
                        {headers: {contentType: 'text/plain; charset=utf-8', "service-type":serviceType}}
                    );
                    const attachment = {
                        id: result.result.id,
                        url: result.result.url,
                        fileName: name,
                        isImage: type?.includes('image')
                    };
                    attachmentsData.push(attachment);
                }
                setAttachments([...attachments, ...attachmentsData]);
                if (editMessageId) {
                    setNewAttachments([...newAttachments, ...attachmentsData]);
                }
                setSendFile(false);
            }
        },
        [attachments, sendFile, editMessageId, newAttachments]
    );

    const removeAttachment = (attachment: any) => {
        if (editMessageId && !newAttachments.includes(attachment)) {
            setDeleteAttachments([...deleteAttachments, attachment.id]);
        }
        setAttachments(attachments?.filter((x: any) => x.id !== attachment.id) ?? []);
    }

    const closeEdit = () => {
        setEditMessageId(null);
        setAttachments([]);
        setDeleteAttachments([]);
        setNewAttachments([]);
        setMessage("");
    }

    const isSendDisabled = () => {
        return message?.trim()?.length === 0 && attachments?.length === 0;
    }

    const onShowRecords = () => {
        setShowChatSettings(false);
        setShowUsers(false);
        setShowSearchMessegies(false);
        setShowRecords(true);
        if (room && roomUserId) {
            requestsRepository.RoomApiService.getRecords(room).then(res => {
                if (res.success) {
                    setRecords(res.result);
                }
            })
        }
    }

    const onShowUsers = () => {
        setShowChatSettings(false);
        setShowUsers(true);
        setShowRecords(false);
        setShowSearchMessegies(false);
    }

    const onShowSearchMessegies = () => {
        setShowChatSettings(false);
        setShowUsers(false);
        setShowRecords(false);
        setShowSearchMessegies(true);
    }

    const backToChat = () => {
        setShowUsers(false);
        setShowRecords(false);
        setShowSearchMessegies(false);
    }

    const onSearchMessegies = () => {
        setSearchMessegies(searchInput?.trim()?.length > 0 ? messages.filter(x => x.messageText.toLowerCase().includes(searchInput.toLowerCase())) : []);
    }

    const onSearchChat = () => {
        setSearchChats(searchChatInput?.trim()?.length > 0 ? chats.filter((x: any) => x.room.name?.toLowerCase().includes(searchChatInput.toLowerCase())) : chats);
    }

    const changeChat = (chat: any) => {
        sendMessageSignal("LeaveChat", null);
        setRoom(chat.roomId);
        const request: any = {
            userId: roomUserId,
            roomId: chat.roomId,
            sessionId,
            serviceType
        }
        sendMessageSignal("JoinChat", request);
        setShowChatSettings(false);
        setShowUsers(false);
        setShowRecords(false);
        setShowSearchMessegies(false);
        setShowList(false);
    }

    const changeChatAccess = (userId: string) => {
        const data = [...users];
        const idx = data.findIndex(d => d.userId === userId);
        data[idx].canRead = false;
        setUsers(data);
    }
    
    const changeIsAdminAccess = (userId: string, isAdmin: boolean) => {
        const data = [...users];
        const idx = data.findIndex(d => d.userId === userId);
        data[idx].isAdmin = isAdmin;
        setUsers(data);
    }

    const toMessage = (id: string) => {
        setScrollToMessage(id);
        setShowSearchMessegies(false);
    }

    return <div className="app-container">
        {fullMode && showChatsList && <div className={`chat-list ${showList ? 'chat-list_show' : ''}`}>
            <div className="chat-search-wrapper">
                <SearchIcon size={13} />
                <div className="input-wrapper">
                    <input value={searchChatInput} onKeyPress={event => {
                        if (event.charCode === 13) {
                            onSearchChat()
                        }
                    }} onChange={(event) => setSearchChatInput(event.target.value)} type="text"
                           className="chat-input" placeholder="Поиск чата"/>
                </div>
            </div>
            <div className="chats-list">
                {searchChats?.map((chat: any, key: number) => <div onClick={() => changeChat(chat)} className={`chat-item ${chat.id === chatId ? 'chat-item_selected' : ''}`} key={key}>
                    <div className={`chat-item__name`}>{chat.room.name}</div>
                    {chat.messages?.length > 0 && <>
                        <div className={`chat-item__user-name`}>{chat.users.find((x: ChatUser) => x.userId === chat.messages?.[0]?.userId)?.userName}</div>
                        <div className={`chat-item__text`}>{chat.messages?.[0]?.messageText?.length > 0 ? chat.messages?.[0]?.messageText : chat.messages?.[0]?.attachments?.length > 0 ? "Вложение" : ""}</div>
                    </>}
                    {chat.unreadUserMessagesCount > 0 && <div className={`unread-messages-count ${chat.id === chatId ? 'unread-messages-count_selected' : ''}`}>{chat.unreadUserMessagesCount > 99 ? `99+` : chat.unreadUserMessagesCount}</div>}
                </div>)}
            </div>
        </div>}
        <div className={`chat-main ${showList ? '' : 'chat-main_show'}`}>
            {fullMode && showChatsList && <div className="chat-back-to-list" onClick={() => setShowList(true)}>
                <span>←</span> Назад к списку
            </div>}
            {fullMode && <div className={`chat-header${fullMode && !showChatsList ? ' chat-header_compact' : ''}`}>
                <div className="chat-name">
                    <ChatNameIcon />
                    {chatName}
                </div>
                <SettingsIcon color={'#147DC1'} ref={ref} className="chat-settings" onClick={() => {
                    setShowChatSettings(true)
                }}>
                    <div style={{display: showChatSettings ? 'flex' : 'none'}}
                         className="message-box-settings-container">
                        <div className="message-box-settings-item" onClick={onShowSearchMessegies}><SearchIcon size={16} />Поиск сообщений</div>
                        {env().REACT_APP_SHOW_VIDEOCHAT_LINK && <div className="message-box-settings-item" onClick={() => {
                            setShowSettings(false);
                            const url = new URL(link ?? window.location.origin+'/'+env().REACT_APP_ROOT_FOLDER+'/');
                            url.searchParams.append('roomId', room ?? "");
                            if (env().REACT_APP_USE_TM_AUTH) {
                                url.searchParams.append('sessionId', sessionId ?? "");
                            } else {
                                url.searchParams.append('userId', roomUserId ?? "");
                            }
                            const blankUrl = `${url.origin}${url.pathname}${url.search}`;
                            window.open(blankUrl, '_blank');
                        }}><CameraIcon color={'#3D4247'} size={16}/>Видеосвязь</div>}
                        <div className="message-box-settings-item" onClick={onShowRecords}><AttachmentIcon size={16}/> Видеозаписи</div>
                        <div className="message-box-settings-item" onClick={onShowUsers}><InfoIcon size={16} />Информация</div>
                    </div>
                </SettingsIcon>
            </div>}
            {!showRecords && !showUsers && !showSearchMessegies && <div className="chat-wrapper" ref={messagesEndRef}>
                {users.length > 0 && messages.length > 0 && Object.keys(getGroupedMessages()).map((data: any, key: any) => <Fragment key={key}><div className="chat-date" >{getDateText(data)}</div>
                    {getGroupedMessages(data).map((message: any, key: any, groupedMessages: any) =>
                        <Messege key={key} message={message} sameNextUser={key !== groupedMessages.length - 1 && groupedMessages[key + 1]?.userId === message?.userId} samePrevUser={key !== 0 && groupedMessages[key - 1]?.userId === message?.userId} visible={visible} messages={messages} setMessage={setMessage} deleteMessage={deleteMessage} setEditMessageId={setEditMessageId} userId={roomUserId}
                                 setReplyMessageId={setReplyMessageId} setAttachments={setAttachments} readMessage={readMessage} unreadMessages={unreadMessages} setShowSettings={setShowSettings} showSettings={showSettings} users={users} compact={fullMode && !showChatsList}/>
                    )}
                </Fragment>)}
            </div>}
            {showSearchMessegies && <div className="chat-wrapper">
                <div className="search-input-container">
                    <div className="input-wrapper">
                        <input value={searchInput} onKeyPress={event => {
                            if (event.charCode === 13) {
                                onSearchMessegies()
                            }
                        }} onChange={(event) => setSearchInput(event.target.value)} type="text"
                               className="chat-input" placeholder="Текст сообщения..."/>
                    </div>
                    <div onClick={onSearchMessegies}
                         className={`chat-send-btn chat-send-btn_name`}>
                        <SearchIcon size={19} color={"#147DC1"}/>
                    </div>
                </div>
                <div>
                    {searchMessegies.map((x, key) => <div onClick={() => toMessage(x.id)} className="search-message-item" key={key}>
                        <div>
                            <div className="search-message-item__name">{getUser(x.userId)?.userName}</div>
                            <div className="search-message-item__text">{x.messageText}</div>
                        </div>
                        <div>
                            <div className="search-message-item__date">{formatDate(x.createdDate, "dd.MMM.yyyy")}</div>
                            <div className="search-message-item__time">{formatDate(x.createdDate, "HH:mm")}</div>
                        </div>
                    </div>)}
                </div>
            </div>}
            {showRecords &&
                <div className="chat-wrapper">
                    <div className="chat-date" >Видеозаписи</div>
                    {records.filter((x: any) => x.endTime !== null).map((record: any, key: number) =>
                        <div onClick={() => {
                            setShowSettings(false);
                            const url = new URL(link ?? window.location.origin+'/'+(env().REACT_APP_ROOT_FOLDER !== "/" ? (env().REACT_APP_ROOT_FOLDER+"/") : env().REACT_APP_ROOT_FOLDER));
                            url.searchParams.append('fileName', record.fileName ?? "");
                            url.searchParams.append(routes.player, "true");
                            console.log(link);
                            console.log(url);
                            window.open(url, '_blank');
                        }} key={key} className={`records-item ${fullMode && !showChatsList ? 'records-item_compact' : ''}`}><div>{formatDate(record.endTime, "HH:mm:ss, dd.MMM.yyyy")}</div><div className="records-item__diff">{timeDiff(new Date(record.endTime), new Date(record.startTime))}</div></div>)}
                </div>}
            {showUsers && <UserInformation users={users} isAdmin={isAdmin} userId={roomUserId} chatId={chatId} changeChatAccess={changeChatAccess} changeIsAdminAccess={changeIsAdminAccess}/>}
            {(showUsers || showRecords || showSearchMessegies) && <div className={`chat-input-wrapper${fullMode && !showChatsList ? ' chat-input-wrapper_compact' : ''}`}>
                <div onClick={backToChat} className="back-to-chat-container"><SendIcon color={"#3D4247"}/><div className="navigation-btn">К сообщениям</div></div>
            </div>}
            {!showUsers && !showRecords && !showSearchMessegies && <div className={`chat-input-wrapper${fullMode && !showChatsList ? ' chat-input-wrapper_compact' : ''}`}>
                {replyMessageId && <div
                    className={`message-wrapper`}>
                    <div className="message-box-wrapper message-box-wrapper-reply message-box-wrapper-edit">
                        <ReplyIcon/>
                        <div className="message-box">
                            <div
                                className="message-box-author">{getUser(getMessage(replyMessageId)?.userId)?.userName ?? "Гость"}</div>
                            <div>
                                {getMessage(replyMessageId)?.messageText}
                                <div className="attachments-container">
                                    {getMessage(replyMessageId)?.attachments?.map((attachment: any, key: number) => <div className="attachment-item" key={key}>
                                        <div className="attachment-item__text"><FileIcon />{attachment.fileName}</div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        <div className="message-box-reply-close" onClick={() => setReplyMessageId(null)}><CloseIcon /></div>
                    </div>
                </div>}
                {editMessageId && <div
                    className={`message-wrapper`}>
                    <div className="message-box-wrapper message-box-wrapper-reply message-box-wrapper-edit">
                        <EditIcon/>
                        <div className="message-box">
                            <div className="message-box-author">Редактирование сообщения</div>
                            <div>
                                {getMessage(editMessageId)?.messageText}
                            </div>
                        </div>
                        <div className="message-box-reply-close" onClick={() => closeEdit()}><CloseIcon /></div>
                    </div>
                </div>}
                <div className="chat-input-container">
                    <label className="add-file-btn">
                        <input type="file" multiple onChange={onChangeFile} />
                        <AttachmentIcon />
                    </label>
                    <div className={`input-wrapper`}>
                        <textarea rows={1} ref={textareaRef} value={message} onKeyPress={event => {
                            if (event.charCode === 13 && !event.shiftKey) {
                                event.preventDefault();
                                if (!isSendDisabled()) {
                                    sendMessage();
                                }
                            }
                        }} onChange={(event) => setMessage(event.target.value)}
                               className="chat-input" placeholder="Напишите сообщение…"/>
                    </div>
                    <div onClick={sendMessage}
                         className={`chat-send-btn ${isSendDisabled() ? 'chat-send-btn_disabled' : ''}`}>
                        <SendIcon color={isSendDisabled() ? "#DADADA" : "#147DC1"}/>
                    </div>
                </div>
                <div className="attachments-container">
                    {attachments?.map((attachment: any, key: number) => <div className="attachment-item" key={key}>
                        <div className="attachment-item__text"><FileIcon />{attachment.fileName}</div>
                        <div className="attachment-item__delete-btn" onClick={() => removeAttachment(attachment)}><DeleteIcon /></div>
                    </div>)}
                </div>
            </div>}
        </div>
    </div>
}